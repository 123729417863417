//AddWeekDetails

import React, { useContext, useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from 'axios';
import toast from 'react-hot-toast';
import { AuthContext } from '../../../../contexts/AuthProvider';

const AddWeekDetails = () => {
    const { userInfo } = useContext(AuthContext)
    const [adminLoading, setAdminLoading] = useState(false);
    const [itemDetails, setItemDetails] = useState();

    useEffect(() => {
        if (userInfo) {
            setAdminLoading(true);
            axios
                .get(
                    `${process.env.REACT_APP_SERVERLESS_API}/api/v1/language/getContentManageSubDetailsByOrganizationAndName/addWeekDetails/organizationsId/${userInfo?.organizationId}`
                )
                .then((response) => {
                    setItemDetails(response?.data);

                })
                .finally(() => {
                    setAdminLoading(false);
                });
        }
        setAdminLoading(false);
    }, [userInfo]);

    const fetchContentDetails = () => {
        if (userInfo) {
            setAdminLoading(true);
            axios
                .get(
                    `${process.env.REACT_APP_SERVERLESS_API}/api/v1/language/getContentManageSubDetailsByOrganizationAndName/addWeekDetails/organizationsId/${userInfo?.organizationId}`
                )
                .then((response) => {
                    setItemDetails(response?.data);
                })
                .finally(() => {
                    setAdminLoading(false);
                });
        }
    };

    console.log(itemDetails)

    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.target;

        const newAddWeekNameName = form.addWeekName?.value;
        const newWeekNameName = form.weekName?.value;
        const newScheduleListName = form.scheduleList?.value;
        const newWeekStartingDateName = form.weekStartingDate?.value;
        const newWeekEndingDateName = form.weekEndingDate?.value;
        const newAddName = form.add?.value;

        const itemDetail = {
            addWeekName: newAddWeekNameName,
            weekName: newWeekNameName,
            scheduleList: newScheduleListName,
            weekStartingDate: newWeekStartingDateName,
            weekEndingDate: newWeekEndingDateName,
            add: newAddName,

        };
        console.log(itemDetail)
        const item = await axios.post(
            `${process.env.REACT_APP_SERVERLESS_API}/api/v1/language/addContentManageSubDetails/addWeekDetails/organizationId/${userInfo?.organizationId}`,
            itemDetail
        );
        console.log(item)
        if (item?.data === "Content Manage Sub Details updated successfully") {
            setItemDetails({ ...itemDetails });
            fetchContentDetails();
            toast.success("Items Name added Successfully");
            form.reset();
        }


    };
    return (
        <div>
            {
                adminLoading ?
                    <div className='flex justify-center'>
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    </div>
                    :
                    <form onSubmit={handleSubmit} className='mt-2 border p-4 rounded-xl'>
                        <div className=' grid grid-cols-2 gap-4'>

                            <div className='flex justify-between items-center w-[100%]'>
                                <p className='text-lg font-medium'>Add Week Name</p>
                                <input name='addWeekName' defaultValue={itemDetails?.addWeekName} type='text' className='border border-slate-300 rounded-lg p-2 w-[300px]' placeholder='Text here' />
                            </div>
                            <div className='flex justify-between items-center w-[100%]'>
                                <p className='text-lg font-medium'>Week Name</p>
                                <input name='weekName'  defaultValue={itemDetails?.weekName} type='text' className='border border-slate-300 rounded-lg p-2 w-[300px]' placeholder='Text here' />
                            </div>
                            <div className='flex justify-between items-center w-[100%]'>
                                <p className='text-lg font-medium'>Schedule List</p>
                                <input name='scheduleList' type='text'  defaultValue={itemDetails?.scheduleList} className='border border-slate-300 rounded-lg p-2 w-[300px]' placeholder='Text here' />
                            </div>
                            <div className='flex justify-between items-center w-[100%]'>
                                <p className='text-lg font-medium'>Week Starting Date</p>
                                <input name='weekStartingDate' defaultValue={itemDetails?.weekStartingDate} type='text' className='border border-slate-300 rounded-lg p-2 w-[300px]' placeholder='Text here' />
                            </div>
                            <div className='flex justify-between items-center w-[100%]'>
                                <p className='text-lg font-medium'>Week Ending Date</p>
                                <input name='weekEndingDate' defaultValue={itemDetails?.weekEndingDate} type='text' className='border border-slate-300 rounded-lg p-2 w-[300px]' placeholder='Text here' />
                            </div>
                            <div className='flex justify-between items-center w-[100%]'>
                                <p className='text-lg font-medium'>Add</p>
                                <input name='add' defaultValue={itemDetails?.add} type='text' className='border border-slate-300 rounded-lg p-2 w-[300px]' placeholder='Text here' />
                            </div>

                        </div>
                        <div className="flex items-center justify-center mt-20 mb-10">
                            <input
                                type="submit"
                                value="Save"

                                className="px-[30px] py-3 bg-[#3E4DAC] hover:bg-opacity-70 text-[#fff] cursor-pointer text-xl font-bold rounded-lg"
                            />

                        </div>

                    </form>
            }
        </div>


    );
};

export default AddWeekDetails;